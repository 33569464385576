import {combineReducers} from "redux";
import auth from "app/auth/store/reducers/index.js";
import localForage from "localforage";
import {createMigrate, persistReducer} from "redux-persist";
import settings, {migrations} from "./settings.reducer.js";

const MIGRATION_DEBUG = false;

const settingsPersistConfig = {
  key: "settingsStore",
  version: 0,
  storage: localForage,
  migrate: createMigrate(migrations, {debug: MIGRATION_DEBUG}),
};

const createReducer = asyncReducers => combineReducers({
  auth,
  settings: persistReducer(settingsPersistConfig, settings),
  ...asyncReducers,
});

export default createReducer;
