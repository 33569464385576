const collection = {
  "Explore all collections": "Découvrez toutes les collections",
  "Filter collections": "Filtrer les collections",
  "Total": "Total",

  "Get share link": "Obtenir le lien de partage",
  "Update collection": "Mettre à jour la collection",
  "New collection": "Nouvelle collection",

  "Collection title": "Titre de la collection",
  "Collection description": "Descriptif de la collection",
  "Add artworks into this collection": "Ajouter des œuvres à cette collection",
  "Update": "Mise à jour",
  "Create": "Créer",
  "Cancel": "Annuler",

  "Total: N collections_one": "Total: {{count}} collection",
  "Total: N collections_other": "Total: {{count}} collections",
};

export default collection;
