const search = {
  "Search artwork": "Search artwork",
  "Verify artwork": "Verify artwork",
  "By hash in decimal format (only number)": "By hash in decimal format (only number)",
  "By file (no single byte of file data is sent to our server)": "By file (no single byte of file data is sent to our server)",
  "Check": "Check",
  "Choose file": "Choose file",
  "No file chosen": "No file chosen",
};

export default search;
