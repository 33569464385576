import homeConfig from "./home/home.config.js";
import artWorkConfig from "./artwork/artwork.config.js";
import authConfig from "./auth/auth.config.js";
import collectionConfig from "./collection/collection.config.js";
import myCollectionConfig from "./mycollection/myCollection.config.js";
import myArtworkConfig from "./myartwork/myArtwork.config.js";
import myNFTConfig from "./mynft/mynft.config.js";
import profileConfig from "./profile/profile.config.js";
import searchConfig from "./search/search.config.js";
import aboutConfig from "./about/about.config.js";
import backupConfig from "./backup/backup.config.js";
import settingsConfig from "./settings/settings.config.js";

const mainConfigs = [
  homeConfig,
  artWorkConfig,
  authConfig,
  myCollectionConfig,
  myArtworkConfig,
  myNFTConfig,
  collectionConfig,
  profileConfig,
  searchConfig,
  aboutConfig,
  backupConfig,
  settingsConfig,
];

export default mainConfigs;
