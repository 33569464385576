import React, {memo, useContext, useEffect, useLayoutEffect} from "react";
import {renderRoutes} from "react-router-config";
import AppContext from "app/app_context.js";
import {ToastContainer} from "react-toastify";

import {useSelector} from "react-redux";
import i18n from "i18n.js";
import {useLocation} from "react-router-dom";
import BackgroundImage from "./components/BackgroundImage";
import NavBar from "./components/Navbar.js";
import Suspense from "./components/Suspense.js";
import Footer from "./components/Footer.js";

const Layout = () => {
  const appContext = useContext(AppContext);
  const {routes} = appContext;
  const _location = useLocation();
  // const matched = matchRoutes(routes, pathname)[0];

  const language = useSelector(state => state.settings.language);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [_location.pathname]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <>
      <NavBar />
      <BackgroundImage />
      <>
        {/* <BetaMark /> */}
        <div>
          <Suspense>
            {renderRoutes(routes)}
          </Suspense>
        </div>
      </>
      <Footer />
      <ToastContainer />
    </>
  );
};

Layout.displayName = "Layout";

export default memo(Layout);
