import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Footer = props => {
  const {t} = useTranslation("common");

  return (
    <footer className="footer">
      <div className="container">
        <div className="columns">
          <div className="column has-text-centered">
            <img className="logo" src="/logo/logo_keeex_white.png" />
          </div>
          <div className="column pt-5">
            <Link to="/why-keeex-nft">
              {t("Why KeeeX NFT?")}
            </Link>
            <Link to="/how-keeex-nft-works">
              {t("How KeeeX NFT works?")}
            </Link>
            <Link to="/partner">
              {t("Partners")}
            </Link>
            <Link to="/terms-of-use">
              {t("Terms of Use")}
            </Link>
          </div>
          <div className="column pt-5">
            <Link to="/">
              {t("Contact")}
            </Link>
            {/* <Link to="/">
              {t("Terms of service")}
            </Link> */}
          </div>
        </div>

      </div>
    </footer>
  );
};

Footer.propTypes = {};

Footer.displayName = "Footer";

export default Footer;
