import React from "react";
import Provider from "react-redux/es/components/Provider.js";
// eslint-disable-next-line import/extensions
import {PersistGate} from "redux-persist/integration/react";
import {Router} from "react-router-dom";

import _history from "@history";

import Authorization from "app/auth/authorization.js";
import Layout from "app/layout/Layout.js";

import AppContext from "./app_context.js";
import routes from "./configs/routes.config.js";
import reduxStore from "./store/index.js";
import {Auth} from "./auth/index.js";

import "app/i18n/i18n.config";

import "styles/app.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-photo-view/dist/react-photo-view.css";

const App = () => (
  <AppContext.Provider value={{routes}}>
    <Provider store={reduxStore.store}>
      <PersistGate loading={null} persistor={reduxStore.persistor}>
        <Auth>
          <Router history={_history}>
            <Authorization>
              <Layout />
            </Authorization>
          </Router>
        </Auth>
      </PersistGate>
    </Provider>
  </AppContext.Provider>
);

App.displayName = "App";

export default App;
