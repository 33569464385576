import {FileVisibilities} from "app/helpers/constants.js";
import * as Actions from "../actions/index.js";

const initialState = () => ({
  error: null,
  isLoading: false,
  user: {
    avatar: "",
    email: "",
    name: "",
  },
  artworkList: {
    accountId: 0,
    visibility: FileVisibilities.everyone,
    limit: 50,
    page: 0,
    total: 0,
    totalPages: 0,
    files: [],
  },
  collectionList: {
    accountId: 0,
    visibility: FileVisibilities.everyone,
    limit: 50,
    page: 0,
    total: 0,
    totalPages: 0,
    categories: [],
  },
});

// eslint-disable-next-line max-lines-per-function
const profileReducer = (state = initialState(), {type, payload} = {}) => {
  switch (type) {
  case Actions.PROFILE_SET_USER:
    return {
      ...state,
      user: payload,
      isLoading: false,
    };
  case Actions.PROFILE_SET_COLLECTION_LIST:
    return {
      ...state,
      collectionList: {
        ...state.collectionList,
        ...payload,
      },
      isLoading: false,
    };
  case Actions.PROFILE_SET_ARTWORK_LIST:
    return {
      ...state,
      artworkList: {
        ...state.artworkList,
        ...payload,
      },
      isLoading: false,
    };
  case Actions.PROFILE_SET_LOAD_STATE:
    return {
      ...state,
      isLoading: payload,
    };
  case Actions.PROFILE_ERROR:
    return {
      ...state,
      isLoading: false,
      error: payload,
    };
  default:
    return state;
  }
};

export default profileReducer;
