const collection = {
  "Backup your key": "Sauvegarde de votre clé",
  "Public address": "Adresse publique",
  "Mnemonic code": "Code mnémonique",
  "The mnemonic code below is used for key recovery in case of forgotten password": "Le code mnémonique ci-dessous est utilisé pour la récupération de la clé en cas d'oubli du mot de passe.",
  "You should write it down and keep it safe": "Vous devriez l'écrire et le garder en sécurité.",
  "Copy mnemonic code": "Copier le code mnémonique",
};

export default collection;
