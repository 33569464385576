import jwtService from "app/services/jwt.js";
import nftService from "app/services/nft.js";

import {showError} from "app/helpers/toastMessage.js";

import * as UserActions from "./user.action.js";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const submitLogin = ({email, password}) => async dispatch => {
  try {
    const user = await jwtService.signInWithEmailAndPassword(email, password);
    dispatch(UserActions.setUserData(user));

    dispatch({type: LOGIN_SUCCESS});

    return user;
  } catch (error) {
    nftService.removeToken();

    showError(error);

    dispatch({
      type: LOGIN_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
