import React from "react";
import {authRoles} from "app/auth/index.js";

import i18next from "../../../i18n.js";

import en from "./i18n/en.js";
import fr from "./i18n/fr.js";

i18next.addResourceBundle("en", "backup", en);
i18next.addResourceBundle("fr", "backup", fr);

const backupConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.user,
  routes: [
    {
      path: "/backup",
      exact: true,
      component: React.lazy(() => import("./backup.js")),
    },
  ],
};

export default backupConfig;
