import * as Actions from "../actions/settings.action.js";

const initialState = () => ({language: "en"});

export const migrations = {0: () => initialState};

const settingsReducer = (state = initialState(), action = {}) => {
  switch (action.type) {
  case Actions.SETTINGS_SET_LANGUAGE:
    return {
      ...state,
      language: action.payload,
    };
  default:
    return state;
  }
};

export default settingsReducer;
