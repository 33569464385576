/* eslint quote-props: ["error", "consistent"] */

const collection = {
  "Backup your key": "Backup your key",
  "Public address": "Public address",
  "Mnemonic code": "Mnemonic code",
  "The mnemonic code below is used for key recovery in case of forgotten password": "The mnemonic code below is used for key recovery in case of forgotten password.",
  "You should write it down and keep it safe": "You should write it down and keep it safe",
  "Copy mnemonic code": "Copy mnemonic code",
};

export default collection;
