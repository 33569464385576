const search = {
  "Search artwork": "Recherche d'œuvres d'art",
  "Verify artwork": "Vérifier l'illustration",
  "By hash in decimal format (only number)": "Par hash au format décimal (uniquement le nombre)",
  "By file (no single byte of file data is sent to our server)": "Par fichier (aucun octet de données de fichier n'est envoyé à notre serveur)",
  "Check": "Vérifiez",
  "Choose file": "Choisir le fichier",
  "No file chosen": "Aucun fichier choisi",
};

export default search;
