import React from "react";
import PropTypes from "prop-types";

const BackgroundImage = props => (
  <div className="background-image" />
);

BackgroundImage.propTypes = {};

BackgroundImage.displayName = "BackgroundImage";

export default BackgroundImage;
