import React from "react";
import {connect} from "react-redux";
import {matchRoutes} from "react-router-config";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import Utils from "app/helpers/utils.js";
import AppContext from "app/app_context.js";

class Authorization extends React.Component {
  // eslint-disable-next-line no-shadow
  constructor(props, context) {
    super(props);
    const {routes} = context;
    this.state = {
      accessGranted: true,
      routes,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {location: _location, userRole} = props;
    const {pathname} = _location;

    const matched = matchRoutes(state.routes, pathname)[0];

    return {
      accessGranted: matched
        ? Utils.hasPermission(matched.route.auth, userRole)
        : true,
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  redirectRoute() {
    const {location: _location, userRole, history: _history} = this.props;
    const {pathname} = _location;
    // const redirectUrl = state && state.redirectUrl ? state.redirectUrl : "/";

    /*
      User is guest
      Redirect to Login Page
    */
    if (!userRole || userRole.length === 0) {
      _history.push({
        pathname: "/login",
        state: {redirectUrl: pathname},
      });
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
      */
      // history.push({
      //   pathname: redirectUrl,
      // });
    }
  }

  render() {
    // return this.state.accessGranted ? <>{this.props.children}</> : null;
    return this.props.children;
  }
}

const mapStateToProps = ({auth}) => ({userRole: auth.user.role});

Authorization.displayName = "Authorization";

Authorization.contextType = AppContext;

Authorization.defaultProps = {userRole: []};

Authorization.propTypes = {
  userRole: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({push: PropTypes.func.isRequired}).isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(connect(mapStateToProps)(Authorization));
