import React from "react";
import {authRoles} from "app/auth/index.js";

import i18next from "../../../i18n.js";

import en from "../collection/i18n/en.js";

i18next.addResourceBundle("en", "collection", en);

const MyCollectionConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.user,
  routes: [
    {
      component: React.lazy(() => import("./MyCollections.js")),
      exact: true,
      path: "/mycollection",
    },
    {
      component: React.lazy(() => import("./CollectionRegister.js")),
      exact: true,
      path: "/mycollection/register",
    },
    {
      component: React.lazy(() => import("./CollectionEdit.js")),
      exact: true,
      path: "/mycollection/:id/edit",
    },
  ],
};

export default MyCollectionConfig;
