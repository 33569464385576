const profile = {
  "Preferences": "Préférences",
  "Keypair": "Votre paire de clés",
  "Keypair message": "Cette page va afficher votre clé privé de manière à ce que vous puissiez l'utiliser dans des outils externes. La connaissance de cette clé privée représente la propriété de vos NFT, elle ne doit donc pas être partagée à la légère avec des tiers  ",
  "Address": "Votre addresse publique",
  "Private key": "Votre clé privée",
  "Show your private key": "Afficher votre clé privée",
  "Cancel": "Annuler",
  "Changing password": "Modification du mot de passe",
  "Change your current password": "Modifier votre mot de passe actuel",
  "Current password": "Mot de passe actuel",
  "Enter current password": "Saisir le mot de passe actuel",
  "New password": "Nouveau mot de passe",
  "Enter new password": "Saisir le nouveau mot de passe",
  "Confirm password": "Confirmer le mot de passe",
  "Confirm new password": "Confirmer le nouveau mot de passe",
  "Change": "Modifier",
  "Change your password": "Modifier le mot de passe",
};

export default profile;
