import React, {
  createRef,
  useCallback,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";

import {createPopper} from "@popperjs/core";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import * as authActions from "app/auth/store/actions/index.js";
import * as settingsActions from "app/store/actions/settings.action.js";

import {NavList, NavListAuth} from "app/helpers/constants.js";
import Utils from "app/helpers/utils.js";

import {showSuccess} from "app/helpers/toastMessage.js";
import {KeyType} from "@keeex/js-keys/lib/shared/types.js";
import Popover from "./Popover.js";

const NavbarItem = ({hiddenDesktop, isHidden, label, onClick, pathname}) => (
  <Link
    className={`navbar-item ${hiddenDesktop ? "is-hidden-desktop" : ""} ${
      isHidden ? "is-hidden" : ""
    }`}
    to={pathname}
    onClick={onClick}
  >
    {label}
  </Link>
);

const NavbarBurger = ({toggleMenu, active}) => (
  <button
    type="button"
    onClick={toggleMenu}
    className={`navbar-burger ${active ? "is-active" : ""}`}
  >
    <span />
    <span />
    <span />
  </button>
);

NavbarBurger.propTypes = {
  active: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

NavbarBurger.displayName = "NavbarBurger";

// eslint-disable-next-line max-lines-per-function
const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  const {t} = useTranslation("common");
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  const {language} = useSelector(state => state.settings);

  const isLoggedIn = Boolean(user.data?.id);

  // popover
  const [popoverShow, setPopoverShow] = useState(false);

  const toggleMenu = useCallback(() => setActiveMenu(pre => !pre), []);
  const closeMenu = useCallback(() => setActiveMenu(false), []);

  const rightIconRef = createRef();
  const popoverRef = createRef();

  const togglePopover = useCallback(() => {
    if (popoverShow) {
      setPopoverShow(false);
      rightIconRef.current.classList.remove("icon-active");
    } else {
      createPopper(rightIconRef.current, popoverRef.current, {placement: "bottom"});

      setPopoverShow(true);
      rightIconRef.current.classList.add("icon-active");
    }
  }, [popoverRef, popoverShow, rightIconRef]);

  const handleLogout = useCallback(() => {
    dispatch(authActions.logoutUser());
    closeMenu();
  }, [dispatch, closeMenu]);

  const handleChangeLanguage = useCallback(
    ({target}) => {
      dispatch(settingsActions.setLanguage(target.getAttribute("name")));
      closeMenu();
    },
    [dispatch, closeMenu],
  );

  const ethKey = useMemo(
    () => user?.data?.wallets?.find(e => e.type === KeyType.ethereum),
    [user?.data?.wallets],
  );

  const handleCopyAddressClicked = useCallback(() => {
    Utils.copyToClipboard(ethKey?.address);
    showSuccess("Public address is copied to clipboard!");
  }, [ethKey?.address]);

  return (
    <nav className="navbar is-fixed-top is-white">
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img className="logo" src="/logo/logo.png" />
          </Link>

          <NavbarBurger active={activeMenu} toggleMenu={toggleMenu} />
        </div>

        <div className={`navbar-menu px-1 ${activeMenu ? "is-active" : ""}`}>
          <div className="navbar-start">
            {ethKey && (
              <>
                <div className="navbar-item is-hidden-desktop">
                  <div className="is-flex is-justify-content-space-between is-align-items-center">
                    <p>{t("Public address")}:</p>
                    <button
                      className="button is-ghost has-tooltip-arrow has-tooltip-bottom"
                      type="button"
                      data-tooltip={t("Copy address")}
                      onClick={handleCopyAddressClicked}
                    >
                      <span className="icon">
                        <i className="fas fa-copy" />
                      </span>
                    </button>
                  </div>

                  <div className="is-flex">
                    <span className="mr-2 truncate is-size-6">
                      {ethKey.address}
                    </span>
                  </div>
                </div>
                <div className="is-divider my-1" />
              </>
            )}

            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link is-arrowless">
                {t("Explore")}
              </div>

              <div className="navbar-dropdown is-boxed">
                <NavbarItem
                  label={t("Artworks")}
                  pathname="/artwork"
                  onClick={closeMenu}
                />
                <NavbarItem
                  label={t("Collections")}
                  pathname="/collection"
                  onClick={closeMenu}
                />
              </div>
            </div>

            {
              isLoggedIn && (
                <div className="navbar-item has-dropdown is-hoverable">
                  <div className="navbar-link is-arrowless">
                    {t("You")}
                  </div>

                  <div className="navbar-dropdown is-boxed">
                    <NavbarItem
                      label={t("My artworks")}
                      pathname="/myartwork"
                      onClick={closeMenu}
                    />
                    <NavbarItem
                      label={t("My collections")}
                      pathname="/mycollection"
                      onClick={closeMenu}
                    />
                    <NavbarItem
                      label={t("My NFTs")}
                      pathname="/mynft"
                      onClick={closeMenu}
                    />
                  </div>
                </div>
              )
            }

            <NavbarItem
              label={t("Search")}
              pathname="/search"
              onClick={closeMenu}
            />

            <div
              className={`is-divider my-1 ${isLoggedIn ? "" : "is-hidden"}`}
            />
            {NavListAuth.map(item => {
              let path = item.pathname;

              if (item.pathname.includes("profile") && isLoggedIn) {
                path = `/profile/${user?.data?.username}`;
              }

              return (
                <NavbarItem
                  key={item.label}
                  isHidden={!isLoggedIn}
                  label={t(item.label)}
                  hiddenDesktop
                  pathname={path}
                  onClick={closeMenu}
                />
              );
            })}
          </div>
          <div className="is-divider my-1" />
          <div className="navbar-end">
            <div
              name="en"
              className={`navbar-item is-clickable ${
                language === "en" ? "has-text-weight-bold" : "has-text-grey"
              } `}
              onClick={handleChangeLanguage}
            >
              EN
            </div>
            <div
              name="fr"
              className={`navbar-item is-clickable ${
                language === "fr" ? "has-text-weight-bold" : "has-text-grey"
              } `}
              onClick={handleChangeLanguage}
            >
              FR
            </div>
            <div
              className={`navbar-item is-clickable is-hidden-touch ${
                !isLoggedIn && "is-hidden"
              }`}
              ref={rightIconRef}
              onClick={togglePopover}
            >
              <span className="icon">
                <i className="far fa-user-circle" />
              </span>
            </div>
            <NavbarItem
              label={t("Login")}
              pathname={"/login"}
              onClick={closeMenu}
              isHidden={isLoggedIn}
            />
            <NavbarItem
              label={t("Logout")}
              pathname={"/"}
              onClick={handleLogout}
              isHidden={!isLoggedIn}
              hiddenDesktop
            />
          </div>
        </div>
      </div>
      <div
        ref={popoverRef}
        className={`${popoverShow ? "" : "is-hidden"} is-hidden-touch`}
      >
        <Popover onClose={togglePopover} user={user} />
      </div>
    </nav>
  );
};

NavbarItem.defaultProps = {
  hiddenDesktop: false,
  onClick: () => {},
  isHidden: false,
};

NavbarItem.propTypes = {
  hiddenDesktop: PropTypes.bool,
  isHidden: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  pathname: PropTypes.string.isRequired,
};

NavbarItem.displayName = "NavbarItem";
Navbar.displayName = "Navbar";
export default Navbar;
