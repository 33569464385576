/* eslint quote-props: ["error", "consistent"] */

const auth = {
  "Welcome to KeeeX NFT": "Bienvenue à  KeeeX NFT",
  "Email": "Courriel",
  "Invalid email": "Courriel non valide",
  "Email is required": "L'adresse électronique est obligatoire",
  "Password": "Mot de passe",
  "Password is required": "Le mot de passe est requis",
  "Don't have account?": "Vous n'avez pas de compte ?",
  "Register here": "Inscrivez-vous ici",

  "Register new account": "Enregistrer un nouveau compte",

  "Name": "Nom",
  "Name is required": "Le nom est obligatoire",
  "Name will be publicly visible on the portal and represents me as a registered artist or as an accredited member or a corporate entity": "Le nom sera visible publiquement sur le portail et me représente en tant qu'artiste inscrit ou en tant que membre accrédité ou personne morale",

  "Username": "Nom d'utilisateur",
  "Username is required": "Le nom d'utilisateur est requis",
  "Username will be publicly used in urls when sharing or displaying content which is free to choose any string here": "Le nom d'utilisateur sera utilisé publiquement dans les urls lors du partage ou de l'affichage du contenu. Vous êtes libre de choisir n'importe quelle chaîne ici",

  "Confirm password": "Confirmer le mot de passe",
  "Passwords do not match": "Les mots de passe ne correspondent pas",
  "Note 1: You won't be able to change name and username after creation": "Note 1: Vous ne pourrez pas changer le nom et le nom d'utilisateur après la création.",
  "Note 2: It will be impossible to access your data without a password": "Note 2: Il sera impossible d'accéder à vos données sans mot de passe. Veillez à sauvegarder votre mot de passe et à le conserver en sécurité.",

  "I agree to the": "Je suis d'accord avec les",
  "terms and conditions": "conditions générales",

  "Register": "S'enregistrer",
  "Have an account already?": "Vous avez déjà un compte ?",
  "Login here": "Connectez-vous ici",

  "Your account is created": "Votre compte est créé",
  "Congratulation! Your account is almost ready.": "Félicitations ! Votre compte est presque prêt.",
  "A confirmation email is sent to your inbox. Please open the mail and follow the link to complete the progress.": "Un e-mail de confirmation est envoyé dans votre boîte de réception. Veuillez ouvrir le mail et suivre le lien pour compléter la progression.",
  "Note: the mail might be in your SPAM box.": "Note : le mail peut se trouver dans votre boîte SPAM.",
  "Go to login": "Aller à la connexion",
};

export default auth;
