export const NavList = [
  {
    pathname: "/artwork",
    label: "Artworks",
  },
  {
    pathname: "/collection",
    label: "Collections",
  },
  {
    pathname: "/search",
    label: "Search",
  },
];

export const NavListAuth = [
  {
    pathname: "/profile",
    label: "My profile",
  },
  {
    pathname: "/myartwork/register",
    label: "Register new artwork",
  },
  {
    pathname: "/settings",
    label: "Preferences",
  },
];

export const ShareTypes = {
  file: "file",
  category: "category",
};

export const FileVisibilities = {
  onlyme: "ONLYME",
  everyone: "EVERYONE",
};

export const FileTypes = {
  source: "SOURCE",
  nft: "NFT",
  attachment: "ATTACHMENT",
};

export const ThumbnailShapes = {
  square: "square",
  respectRatio: "respect ratio",
};

export const AccountTypes = {
  visitor: "VISITOR",
  tester: "TESTER",
  creator: "CREATOR",
};

export const TradingPlaces = [
  {label: "KeeeX Blockchain"},
  {label: "OpenSea"},
  {label: "SuperRare"},
  {label: "Rarible"},
  {label: "SoRare"},
];

export const SuccessCode = 200;

export const NFTIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAHYAAAB2AH6XKZyAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAA3NJREFUeJztm09IFUEcxz9WIpmgUkJgmZgURv8klCJKiyAIPAR1irpEQSV0rGPHCJLqUHQs6NK1OlVQQUGHIAzp9cf+Wl08lOmT/qgdZh/Mm/bt252dnVnd94Efb3ff/Nbfd3Rnfs7+BtywAOgHhj3r965lgp3AIDCjWA7Y4zCuxGkHbvK/cNXuAmscxZgIi4AzwCTlxRfsN3ARqLcfrjmqgEPAN8ILV20UOAnMtxx7bLqBJ+gLV+0ZsM2qAk2agevANObEy3YLaLUlJgoLgVPAT5IRLlseOAvUWVEWgj7gPckLV20EMcZUJS/Rn07gUUCAtuwpsDlhrUUsRkxRfw2KiGvTiLFnaYK6qUZMSd9TILiUjSNyjhrT4ncBQykQGNbeAPtNCF8N3EmBIF27D6zTEd6ImGp+pUBEXPsDXAWaworfgUhBXQdu2kY9bWXJpSDYpCynivVLIvKIzG4uMgnUyhfmOQokNVQ6wHUArql0gOsAXJP5DtBZi38JHAW+euf1wAVgu9TmNXAEsUBynuIE5ANw2PsMogm4hFheewEcQ6wvBtHo/byeMu0CyROcTPit3a9ApJyFNvuk79oV/wMRYun2fHZH8OkIiD2vNtZ5BL74XPuIWK/za/M5hH8pqpXPMET6qzb5OuoysFfDry3g+oB3PABMAa+88xHEuwMQv8RW77gFOKcRQxHlHoENJfyqEOPDDLBFul6j+Pcqfjo5/UbJvyGCn5FHoBQzwBWD97NC3A6oofj5vAZMxLynVeJ2QC3Fz/0P4EbEe6xUrAd4HjOuWEQZAxqBB4r/eqKNAX5sLRNDqsaAHmCtdD6ISFxmBaamweOeFRiP4KtOg82IDNAKpjrgIHAaGNPwHTYUgxampsE6RCfMOkzmASdw+KJSF50OWCYdt0jHHfgvOy8P6W8iBiOUmwaHENUaXcBj5buc912bZ5sQ06Tc5h2iUqwTURClkwoHxRBpGqwsizsKJDVUOsB1AK6pdIDrAFxT6QCfa5+sR2GPUNrmcoFEb9ieamDulcgsCSteZhVwOwUidO0emkVSKpktk5PJdKGkTBpLZaewUCqr0gk8NChC16wXS6v0If7Hty3cebm8TGHDxBjJC58gZRsmZDK7ZUali4xumpLJ9LY5mcxunFSJsnW2w1GMVsjs5mkZefv8Wxxun/8HDqmDEsOLktkAAAAASUVORK5CYII=";
