import jwtService from "app/services/jwt.js";
import {merge} from "lodash";

export const SET_USER_DATA = "[USER] SET DATA";
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";

/**
 * Set User Data
 */
export const setUserData = user => dispatch => {
    // Set User Data
  dispatch({
    type: SET_USER_DATA,
    payload: user,
  });
};

/**
 * Update User Data
 */
// const updateUserData = (user, dispatch) => {
const updateUserData = user => {
  if (!user.role || user.role.length === 0) {
    return;
  }

  return jwtService.updateUserData(user);
};

/**
 * Update User Settings
 */
export const updateUserSettings = settings => (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = merge({}, oldUser, {data: {settings}});

  updateUserData(user, dispatch);

  return dispatch(setUserData(user));
};

/**
 * Update User Shortcuts
 */
export const updateUserShortcuts = shortcuts => (dispatch, getState) => {
  const {user} = getState().auth;
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts,
    },
  };

  updateUserData(newUser, dispatch);

  return dispatch(setUserData(newUser));
};

/**
 * Remove User Data
 */
export const removeUserData = () => ({type: REMOVE_USER_DATA});

/**
 * Logout
 */
export const logoutUser = () => (dispatch, getState) => {
  const {user} = getState().auth;

  if (!user.role || user.role.length === 0) {
      // is guest
    return null;
  }

  jwtService.logout();

    // history.push("/login");

  return dispatch({type: USER_LOGGED_OUT});
};
