import React from "react";
// import {Redirect} from "react-router-dom";
import {authRoles} from "app/auth/index.js";

import i18next from "../../../i18n.js";

import en from "./i18n/en.js";
import fr from "./i18n/fr.js";

i18next.addResourceBundle("en", "artwork", en);
i18next.addResourceBundle("fr", "artwork", fr);

const ArtworkConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/artwork",
      exact: true,
      component: React.lazy(() => import("./Artworks.js")),
    },
    {
      path: "/artwork/token/:token",
      exact: true,
      component: React.lazy(() => import("../myartwork/Artwork.js")),
    },
    {
      path: "/artwork/:hash",
      exact: true,
      component: React.lazy(() => import("../myartwork/Artwork.js")),
    },
  ],
};

export default ArtworkConfig;
