/* eslint quote-props: ["error", "consistent"] */
const profile = {
  "Preferences": "Preferences",
  "Keypair": "Your keypair",
  "Keypair message": "This page will display your private key so that you can use it in external tools. Knowledge of this private key is the same as having ownership on your NFT so it must not be shared lightly with third parties",
  "Address": "Your public address",
  "Private key": "Your private key",
  "Show your private key": "Show your private key",
  "Cancel": "Cancel",
  "Changing password": "Changing password",
  "Change your current password": "Change your current password",
  "Current password": "Current password",
  "Enter current password": "Enter current password",
  "New password": "New password",
  "Enter new password": "Enter new password",
  "Confirm password": "Confirm password",
  "Confirm new password": "Confirm new password",
  "Change": "Change",
  "Change your password": "Change your password",
};

export default profile;
