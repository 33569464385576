/* eslint quote-props: ["error", "consistent"] */

const auth = {
  "Welcome to KeeeX NFT": "Welcome to KeeeX NFT",
  "Email": "Email",
  "Invalid email": "Invalid email",
  "Email is required": "Email is required",
  "Password": "Password",
  "Password is required": "Password is required",
  "Don't have account?": "Don't have account?",
  "Register here": "Register here",

  "Register new account": "Register new account",

  "Name": "Name",
  "Name is required": "Name is required",
  "Name will be publicly visible on the portal and represents me as a registered artist or as an accredited member or a corporate entity": "Name will be publicly visible on the portal and represents me as a registered artist or as an accredited member or a corporate entity",

  "Username": "Username",
  "Username is required": "Username is required",
  "Username will be publicly used in urls when sharing or displaying content which is free to choose any string here": "Username will be publicly used in urls when sharing or displaying content which is free to choose any string here",

  "Confirm password": "Confirm password",
  "Passwords do not match": "Passwords do not match",
  "Note 1: You won't be able to change name and username after creation": "Note 1: You won't be able to change name and username after creation.",
  "Note 2: It will be impossible to access your data without a password": "Note 2: It will be impossible to access your data without a password. Make sure to backup your password and keep it safe.",

  "I agree to the": "I agree to the",
  "terms and conditions": "terms and conditions",

  "Register": "Register",
  "Have an account already?": "Have an account already?",
  "Login here": "Login here",

  "Your account is created": "Your account is created",
  "Congratulation! Your account is almost ready.": "Congratulation ! Your account is almost ready.",
  "A confirmation email is sent to your inbox. Please open the mail and follow the link to complete the progress.": "A confirmation email is sent to your inbox. Please open the mail and follow the link to complete the progress.",
  "Note: the mail might be in your SPAM box.": "Note: the mail might be in your SPAM box.",
  "Go to login": "Go to login",
};

export default auth;
