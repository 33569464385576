import React from "react";
import {authRoles} from "app/auth/index.js";

import i18next from "../../../i18n.js";

import en from "../collection/i18n/en.js";

i18next.addResourceBundle("en", "collection", en);

const MyArtworkConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.user,
  routes: [
    {
      path: "/myartwork",
      exact: true,
      component: React.lazy(() => import("./MyArtworks.js")),
    },
    {
      path: "/myartwork/register",
      exact: true,
      component: React.lazy(() => import("./ArtworkRegister.js")),
    },
    {
      path: "/myartwork/:hash/create-nft",
      exact: true,
      component: React.lazy(() => import("./ArtworkCreateNFT.js")),
    },
  ],
};

export default MyArtworkConfig;
