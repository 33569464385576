import jwtDecode from "jwt-decode";

import _history from "@history";

import {EventEmitter} from "app/helpers/utils.js";
import apiConfig from "app/configs/api.config";
import {authRoles} from "app/auth/index.js";

import {KeyType} from "@keeex/js-keys/lib/shared/types.js";
import nftService from "./nft.js";
import keyService from "./key.js";

const secondTicks = 1000;

class JwtService extends EventEmitter {
  init() {
    this.handleAuthentication();
  }

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      this.emit("onNoAccessToken");
      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  }

  async signInWithEmailAndPassword(email, password) {
    const res = await nftService.login(email, password);
    let firstTime = false;

    this.setSession(res.token);

    const activeAddresses = await nftService.getActiveAddresses();
    const wallets = activeAddresses;

    const bitcoinAddress = activeAddresses.find(e => e.type === KeyType.bitcoin);

    if (bitcoinAddress) {
      // Already have one => retrieve it from Vault
      await keyService.retrieve(password, bitcoinAddress.address);
    } else {
      firstTime = true;
      // Generate new keypair
      await keyService.genBitcoinKey();

      // Register key to Vault
      await keyService.register(password);

      // Add address to NFT
      const signature = await keyService.sign(email);
      await nftService.addAddress(KeyType.bitcoin, keyService.bitcoinAddress, signature);
      wallets.push({
        type: KeyType.bitcoin,
        address: keyService.bitcoinAddress,
      });
    }

    const ethAddress = activeAddresses.find(e => e.type === KeyType.ethereum);
    if (ethAddress) {
      // check if it is same as exported from bitcoin key
      // eslint-disable-next-line no-console
      console.log(ethAddress.address === keyService.ethAddress);
    } else {
      // Add Ethereum address to NFT
      const signature = await keyService.ethSign(email);
      await nftService.addAddress(KeyType.ethereum, keyService.ethAddress, signature);
      wallets.push({
        type: KeyType.ethereum,
        address: keyService.ethAddress,
      });
    }

    const user = {
      firstTime,
      data: {...res.account, wallets},
      role: authRoles.user,
      redirectUrl: firstTime ? "/backup" : "/",
    };

    return user;
  }

  async signInWithToken() {
    try {
      const res = await nftService.getAccountInfo();
      if (!res) {
        throw new Error("Failed to login with token.");
      }

      this.setSession(res.token);

      return {
        data: res.account,
        role: authRoles.user,
      };
    } catch (error) {
      this.logout();
      _history.push("/login");
      throw new Error("Failed to login with token.");
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setSession(accessToken) {
    if (accessToken) {
      nftService.setToken(accessToken);
    } else {
      nftService.removeToken();
    }
  }

  logout() {
    this.setSession(null);
  }

  // eslint-disable-next-line class-methods-use-this
  isAuthTokenValid(accessToken) {
    if (!accessToken) {
      return false;
    }

    try {
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / secondTicks;
      if (decoded.exp < currentTime) {
        _history.push("/login");
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getAccessToken() {
    return window.localStorage.getItem(apiConfig.nftTokenKey);
  }
}

const instance = new JwtService();

export default instance;
