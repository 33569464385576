const common = {
  // Menu
  "Artworks": "Artworks",
  "Collections": "Collections",
  "Search": "Search",
  "About": "About",
  "How": "How",
  "Why": "Why",

  "Explore": "Explore",
  "You": "You",

  "My profile": "My profile",
  "Register new artwork": "Register new artwork",
  "My artworks": "My artworks",
  "My collections": "My collections",
  "My NFTs": "My NFTs",
  "Preferences": "Preferences",

  "Logout": "Logout",
  "Login": "Login",

  // Layout
  "This is beta version": "This is beta version",

  "available": "available",

  // pagination
  "Empty data": "Empty data",
  "items per page": "items per page",
  "of": "of",
  "Previous page": "Previous page",
  "Previous": "Previous",
  "Next page": "Next page",
  "Next": "Next",

  // Dropzone
  "Drop files here or click to upload": "Drop files here or click to upload",
  "Or": "Or",
  "Click to select files": "Click to select files",
  "Drop some photos here": "Drop photos or a folder here",
  "N files selected_one": "{{count}} file selected",
  "N files selected_other": "{{count}} files selected",

  // VisitorWrapper
  "We still need to keep this access closed to unwanted people": "This access is closed to uninvited or non paying customers.",
  "Please contact us if you want to access this page": "Please contact us if you want to access this feature:",

  //
  "item_one": "item",
  "item_other": "items",

  "artwork_one": "artwork",
  "artwork_other": "artworks",

  "collection_one": "collection",
  "collection_other": "collections",

  "NFT_one": "NFT",
  "NFT_other": "NFTs",

  "Public address": "Public address",
  "Copy address": "Copy address",
  "Copy": "Copy",

  "N artworks_one": "{{count}} artwork",
  "N artworks_other": "{{count}} artworks",
  "N collections_one": "{{count}} collection",
  "N collections_other": "{{count}} collections",

  "Why KeeeX NFT?": "Why KeeeX NFT?",
  "How KeeeX NFT works?": "How KeeeX NFT works?",
  "Partners": "Partners",
  "Contact": "Contact",
  "Terms of service": "Terms of service",
  "Terms of Use": "Terms of Use",

  "Go back to homepage": "Go back to homepage",
};

export default common;
