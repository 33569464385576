import "@keeex/crypto-provider-browser";
import {buf82hex} from "@keeex/js-utils/lib/uint8array.js";

import {
  generateKey,
  importKey,
} from "@keeex/js-keys/lib/keys.js";
import {KeyType} from "@keeex/js-keys/lib/shared/types.js";

import VaultClientSDK from "@keeex/js-vaultsdk/lib/client.js";

import apiConfig from "app/configs/api.config.js";
import {RecoveryMethod} from "@keeex/js-keys/lib/utils/recovery";
import {UpdateType} from "@keeex/js-vaultsdk/lib/client/types";

export class KeyService {
  constructor() {
    this._generated = false;
    this._bitcoinKey = null;
    this._ethereumKey = null;
    this._vault = null;
  }

  get bitcoinKey() {
    return this._bitcoinKey;
  }

  get hasBitcoinKey() {
    return (this._bitcoinKey !== null);
  }

  get bitcoinAddress() {
    return this._bitcoinKey ? this._bitcoinKey.getAddress() : null;
  }

  get bitcoinPublicKey() {
    return this._bitcoinKey ? this._bitcoinKey.getPublic() : null;
  }

  get ethAddress() {
    return this._ethereumKey ? this._ethereumKey.getAddress() : null;
  }

  initVault(authToken) {
    this._vault = new VaultClientSDK(
      `${apiConfig.baseURL}/account/vault`,
      {"Authorization": `Bearer ${authToken}`},
      true,
    );
  }

  clear() {
    this._bitcoinKey = null;
    this._ethereumKey = null;
  }

  async setBitcoinKey(btcKey) {
    this._generated = false;
    this._bitcoinKey = btcKey;
    await this.#btcToEthKey();
  }

  async genBitcoinKey() {
    this._generated = true;
    const key = await generateKey(KeyType.bitcoin);
    this._bitcoinKey = key;

    await this.#btcToEthKey();

    return key;
  }

  _exportBitcoinKey() {
    return this._bitcoinKey.exportKey(false, {type: "json"});
  }

  async exportBitcoinKey(password) {
    if (!this._bitcoinKey) return null;

    if (password) {
      const securedKey = await this._bitcoinKey.exportKey(
        false,
        {
          type: "seal",
          password,
        },
      );

      return Buffer.from(securedKey).toString();
    }

    const exportedKey = await this._bitcoinKey.exportKey(false, {type: "json"});

    return Buffer.from(exportedKey).toString();
  }

  async getBitcoinPublic() {
    if (!this._bitcoinKey) return null;

    return buf82hex(await this._bitcoinKey.getPublic());
  }

  async getBitcoinPrivateRaw() {
    if (!this._bitcoinKey) return null;

    return buf82hex(await this._bitcoinKey.getPrivateRaw());
  }

  async sign(message) {
    if (!this._bitcoinKey) return null;

    const signature = await this._bitcoinKey.sign(message);

    return signature;
  }

  async ethSign(message) {
    if (!this._ethereumKey) return null;

    const signature = await this._ethereumKey.sign(message);

    return signature;
  }

  register(password) {
    if (!this._bitcoinKey) return null;

    return this._vault.register(this._bitcoinKey, password);
  }

  async retrieve(password, address) {
    const key = await this._vault.retrieve(address, password);
    this._bitcoinKey = key;

    await this.#btcToEthKey();
  }

  async #btcToEthKey() {
    this._ethereumKey = await importKey(KeyType.ethereum, {
      type: "privateKey",
      privateKeyHex: buf82hex(await this._bitcoinKey.getPrivateRaw()),
    });
  }

  /**
   * Sign list of messages
   *
   * @param { Array<?> } list of messages
   *
   * @returns { Object<message, signature> }
   */
  async signMessages(messages) {
    const signatures = {};

    for (const message of messages) {
      // eslint-disable-next-line no-await-in-loop
      signatures[message] = await this.sign(message);
    }

    return signatures;
  }

  /**
   * Sign list of pending files returned from Keeex Chains
   *
   * @param {string[]} pendingFiles
   * @param {Object} pendingFiles[].kxdata
   * @param {String} pendingFiles[].kxdata.idx
   *
   * @returns { Object }
   */
  signPendingFiles(pendingFiles) {
    const convertedFiles = pendingFiles.map(item => {
      const obj = JSON.parse(Buffer.from(item, "base64"));
      return obj;
    });

    const messages = convertedFiles.map(file => file.kxdata.idx);

    return this.signMessages(messages);
  }

  async getRecovery() {
    try {
      if (this._bitcoinKey?.getRecovery) {
        const data = await this._bitcoinKey.getRecovery(RecoveryMethod.bip39);
        return data;
      }

      return null;
    } catch (err) {
      return null;
    }

    // const tmp = await generateKey(KeyType.bitcoin);

    // return tmp.getRecovery(RecoveryMethod.bip39);
  }

  async resetPassword(newPassword) {
    if (!this.bitcoinAddress) {
      return false;
    }

    await this._vault.update({
      type: UpdateType.key,
      key: this._bitcoinKey,
      newPassword,
      purgeAllKeys: true,
    });

    return true;
  }

  async updatePassword(oldPassword, newPassword) {
    if (!this.bitcoinAddress) {
      return false;
    }

    await this._vault.update({
      type: UpdateType.password,
      keyAddress: this.bitcoinAddress,
      oldPassword,
      newPassword,
      purgeAllKeys: true,
    });

    return true;
  }
}

const _instance = new KeyService();

export default _instance;
