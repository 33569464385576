const artwork = {
  "Name is required": "Le nom est requis",
  "File is required": "Le fichier est requis",

  "Explore all artworks": "Découvrez toutes les oeuvres",
  "Filter artworks": "Filtrer les oeuvres",

  "Total": "Total",

  "NFT thumbnail": "Vignette NFT",
  "Show blockchain": "Afficher la chaîne de blocs",
  "Copy token ID": "Copier l'ID du jeton",

  "Original artwork": "Oeuvre originale",
  "ORIGINAL": "ORIGINAL",
  "Publish artwork": "Publier l'œuvre",
  "Download": "Télécharger",
  "Download tooltip": "Ce fichier est exclusivement à la disposition du propriétaire de NFT, utilisé pour l'affichage à domicile ou pour d'autres utilisations selon la licence accordée",
  "Download thumbnail": "Télécharger la vignette",
  "Download thumbnail tooltip": "C'est le seul élément que vous devriez rendre public, et utiliser pour le partage sur les plateformes artistiques ou les places de marché",
  "Share": "Partager",
  "Share tooltip": "Obtenir le lien de partage",
  "Contact": "Contacter",
  "Contact tooltip": "Contact avec le propriétaire",
  "Present in": "Présenter à",
  "Cancel": "Annuler",
  "Add to collection": "Ajouter à collection",
  "Filter collections": "Filtrer les collections",

  "Register": "Créer",

  "New artwork": "Nouvelle oeuvre",
  "Published": "Publié",
  "Private": "Privé",
  "NFT wallet": "Portefeuille NFT",
  "NFTs issued": "NFT émis",
  "My NFTs": "Mes NFTs",

  "This artwork will be published with everybody": "Cette oeuvre sera publiée avec tout le monde",
  "You cannot undo this action": "Vous ne pouvez pas annuler cette action",
  "Publish": "Publier",

  "transferable": "transférable",
  "transferred": "transféré",

  "Transfer": "Transférer",
  "Transfer NFT": "Transfert NFT",

  "Receiver address is required": "L'adresse du destinataire est obligatoire",
  "Receiver address": "Adresse du destinataire",
  "Trading place is required": "La place de marché est obligatoire",
  "Trading place": "La place de marché",
  "Token ID is required": "L'ID du jeton est obligatoire",
  "Token ID on redirect contract": "L'ID du jeton sur le contrat de redirection",

  "and": "et",
  "more": "Suite",

  "Message is required": "Un message est requis",
  "Send": "Envoyer",
  "Message": "Message",

  "NFT notification": "L'image miniature doit être utilisée sur la plate-forme NFT: <0 alt='artwork' href='{{link}}' target='_blank' rel='noreferrer'>lien de téléchargement</0>.\nLe fichier NFT réel (sans filigrane) doit être réservé aux propriétaires vérifiés. Certaines plateformes permettent d'afficher du texte masqué uniquement pour les acheteurs.\nVous pouvez décider d'y coller le lien de téléchargement NFT, ou un moyen de vous contacter.\nNotez que vous devez coller l'IDX du NFT dans la partie description de votre NFT sur la plate-forme cible.\nProfitez de votre voyage plus sûr.",
  "Create NFTs": "Créer des NFTs",
  "Publish all uploading photos": "Publier toutes les photos téléchargées",
  "Generate an NFT for every uploading photo": "Générer un NFT pour chaque photo téléchargée",
  "The photo or photos are digital twins for a physical work of art": "La ou les photos sont des doubles numériques représentant des oeuvres d'art physiques",
  "I certify that I have the right to perform this action": "Je certifie avoir le droit de réaliser cette action",

  "ID for searching": "ID pour la recherche",
  "Full IDX": "IDX complet",
  "Name": "Nom",
  "File": "Le fichier",
  "Choose file": "Choisir le fichier",
  "Attach file": "Joindre le fichier",
  "Attach": "Joindre",
  "Attached files": "Fichiers attachés",
  "Attach new file": "Attacher un nouveau fichier",

  "Delete artwork": "Effacer l'œuvre",
  "Delete artwork tooltip": "Effacer l'œuvre",
  "This artwork and related file will be deleted": "Cette œuvre et le fichier correspondant seront supprimés",
  "You will not be able to reverse this action": "Vous ne pourrez pas annuler cette action",

  "Artwork title": "Préfixe du titre de l'oeuvre",
  "Artwork title placeholder": "Préfixe du titre de l'oeuvre - sera complété par le nom de fichier (hors extension)",
  "Artwork title comment": "Laisser préfixe du titre de l'oeuvre vide si inutile",
  "Artwork description": "Description de l'œuvre",
  "Artwork description placeholder": "Description de l'œuvre (sera générique si vous déposez une série de photos)",
  "NFT description": "Description du NFT",
  "NFT description placeholder": "Description du NFT",
  "NFT license": "NFT licence",
  "NFT license placeholder": "Indiquez le nom de la licence et son IDX (si elle est keeexée).\nE.g: KeeeX NFT Basic License - xiker-tocyp-magib-miduc-filyh-kedad-lyzit-kypek-vopim-sevid-rohug-pavul-hiloc-timif-kidur-ramek-dixyx",
  "Creating: {{createdTokens}} / {{totalTokens}} KeeeX NFTs": "Création: {{createdTokens}} / {{totalTokens}} KeeeX NFTs.",
  "Note: don't close this browser tab while processing": "Note : ne fermez pas cet onglet du navigateur pendant le traitement.",
  "See": "Voir",
  "Create [{{name}}] collection": "Créer la collection [{{name}}]",
  "Delete": "Effacer",
  "My public address": "Mon adresse publique",
  "Artwork": "Oeuvre d'art",

  "N NFTs_one": "{{count}} NFT",
  "N NFTs_other": "{{count}} NFTs",
  "N NFTs available_one": "{{count}} NFT minté",
  "N NFTs available_other": "{{count}} NFTs mintés",
  "N NFTs transferable_one": "{{count}} NFT transférable",
  "N NFTs transferable_other": "{{count}} NFTs transférables",
  "N NFTs transferred_one": "{{count}} NFT transféré",
  "N NFTs transferred_other": "{{count}} NFTs transférés",
  "Present in N collections_one": "Attachée à {{count}} collection",
  "Present in N collections_other": "Attachée à {{count}} collections",
  "Total: N artworks_one": "Total: {{count}} œuvre d'art",
  "Total: N artworks_other": "Total: {{count}} œuvres d'art",
  "Total: N NFTs_one": "Total: {{count}} NFT",
  "Total: N NFTs_other": "Total: {{count}} NFTs",

  "Collection and N more": "{{name}} et {{count}} plus",

  "View all artworks": "Afficher toutes les oeuvres",
  "View artworks having NFT": "Afficher les oeuvres ayant un NFT",
  "View artworks presenting in a collection": "Afficher les oeuvres attachés à une collection",
  "View all my artworks": "Afficher toutes mes œuvres",
  "View public artworks only": "Afficher uniquement les œuvres publiques",
  "View private artworks only": "Afficher uniquement les œuvres privées",
  "View artworks not presented in any collection": "Afficher les œuvres non attachés à une collection",
  "View all my NFTs": "Afficher tous mes NFTs",
  "View NFTs issued by me": "Afficher les NFTs émis par moi",
  "View NFTs received from someone": "Afficher les NFTs reçus de quelqu'un",
  "View NFTs transferred to someone": "Afficher les NFTs transférés à quelqu'un",

  "Create some NFTs for this artwork": "Créez quelques KeeeX NFTs pour cette œuvre d'art",
  "Number of KeeeX NFTs": "Nombre de KeeeX NFTs",
  "Submit": "Soumettre",

  "Redirect message": "Déplacer ce NFT vers une autre blockchain / smart contract au lieu de le transférer à un autre usager du portail.",
  "Redirect warning message": "Soyez très prudent, ni les opérations de transfert interne ni la migration externe ne peuvent être annulées.",
};

export default artwork;
