/* eslint quote-props: ["error", "consistent"] */

const collection = {
  "Secure NFT": "Secure NFT, by KeeeX",
  "Create, Collect, Exchange": "Create, Collect, Transfer",
  "New collections": "New collections",
  "New artworks": "New artworks",
  "Create your NFT": "Create your NFT",
};

export default collection;
