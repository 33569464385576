import axios from "axios";
import {setProvider} from "@keeex/crypto";
import browerProvider from "@keeex/crypto-provider-browser";
import {Algorithms, digestString} from "@keeex/crypto/lib/digest.js";
import {ab2hex} from "@keeex/js-utils/lib/arraybuffer.js";

import apiConfig from "app/configs/api.config";

import keyService from "./key.js";

setProvider(browerProvider);

const nftApi = axios.create({
  baseURL: apiConfig.baseURL,
  headers: {"X-Requested-With": "XMLHttpRequest"},
  withCredentials: true,
});

const setToken = accessToken => {
  localStorage.setItem(apiConfig.nftTokenKey, accessToken);
  nftApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  keyService.initVault(accessToken);
};

const removeToken = () => {
  delete nftApi.defaults.headers.common.Authorization;
  localStorage.removeItem(apiConfig.nftTokenKey);
};

const login = async (email, password) => {
  const derivedPassword = ab2hex(
    await digestString(Algorithms.SHA256, password),
  );

  const res = await nftApi.post("/auth/login", {
    email,
    password: derivedPassword,
    version: 1,
  });
  if (!res.data.token) throw res.data.error;

  return res.data;
};

const register = async (email, password, _name, username) => {
  const derivedPassword = ab2hex(
    await digestString(Algorithms.SHA256, password),
  );

  const res = await nftApi.post("/auth/register", {
    email,
    password: derivedPassword,
    name: _name,
    username,
  });

  return res.data;
};

const getAccountInfo = async () => {
  const res = await nftApi.get("/account");
  if (!res.data.token) throw res.data.error;

  return res.data;
};

export const updateAccountInfo = async data => {
  const res = await nftApi.put("/account", data);
  return res.data;
};

const getAccountInfoPublished = async username => {
  const res = await nftApi.get(`/account/public/${username}`);
  return res.data;
};

const addAddress = async (type, address, signature) => {
  const res = await nftApi.post("/account/address", {
    type,
    address,
    signature,
  });

  return res.data;
};

const getAddresses = async () => {
  const res = await nftApi.get("/account/address");
  return res.data;
};

export const getActiveAddresses = async () => {
  const res = await nftApi.get("/account/address/active");
  return res.data;
};

export const updateAvatar = async (formData, config) => {
  const res = await nftApi.put("/account/avatar", formData, config);
  return res.data;
};

export const updatePassword = async ({password, newPassword}) => {
  const derivedPassword = ab2hex(
    await digestString(Algorithms.SHA256, password),
  );

  const derivedNewPassword = ab2hex(
    await digestString(Algorithms.SHA256, newPassword),
  );

  const res = await nftApi.put("/account/password", {
    password: derivedPassword,
    newPassword: derivedNewPassword,
  });

  return res.data;
};

export const fetchIssuedNFTs = async params => {
  const res = await nftApi.get("/file/nft/list/issued", {params});
  return res.data;
};

export const fetchNFTs = async params => {
  const res = await nftApi.get("/file/nft/list", {params});
  return res.data;
};

export const fetchFileList = async params => {
  const res = await nftApi.get("/file/list", {params});
  return res.data;
};

export const fetchFileHavingNft = async params => {
  const res = await nftApi.get("/file/list/having-nft", {params});
  return res.data;
};

export const fetchFileNoCollection = async params => {
  const res = await nftApi.get("/file/list/no-collection", {params});
  return res.data;
};

export const getLatestFiles = async () => {
  const res = await nftApi.get("/file/latest");
  return res.data;
};

const fetchFileByHash = async hash => {
  const res = await nftApi.get("/file", {params: {hash}});
  return res.data;
};

const registerFile = async (formData, config) => {
  const res = await nftApi.post("/file/register", formData, config);
  return res.data;
};

const publishFile = async data => {
  const res = await nftApi.post("/file/publish", data);
  return res.data;
};

/**
 * Make NFT
 * @param {object} data
 * @param {number} data.totalNFT
 * @param {string} data.hash
 * @param {string} data.tradingPlace
 * @param {number} data.address
 */
const makeNFTs = async data => {
  const res = await nftApi.post("/file/nft", data);
  return res.data;
};

const getNFTs = async hash => {
  const res = await nftApi.get("/file/nft", {params: {hash}});
  return res.data;
};

const signFile = async data => {
  const res = await nftApi.post("/file/register/sign", data);
  return res.data;
};

const signNFT = async data => {
  const res = await nftApi.post("/file/nft/sign", data);
  return res.data;
};

const downloadFile = async hash => {
  const res = await nftApi.get("/file/download", {
    params: {hash},
    responseType: "blob",
  });
  return res.data;
};

/**
 * Attach file to nft
 * @param {object} data
 * @param {string} data.srcHash hash of attaching file
 * @param {string} data.dstHash hash of target NFT
 */
const attach = async data => {
  const res = await nftApi.post("/file/nft/attach", data);
  return res.status;
};

/**
 * Get attached files
 */
const getAttachedFiles = async params => {
  const res = await nftApi.get("file/nft/attach", {params});
  return res.data;
};

/**
 * Delete file
 */
const deleteFile = async hash => {
  const res = await nftApi.delete("file", {params: {hash}});
  return res.status;
};

const fetchCategories = async params => {
  const res = await nftApi.get("/category/list", {params});
  return res.data;
};

export const getLatestCategories = async () => {
  const res = await nftApi.get("/category/latest");
  return res.data;
};

const createCategory = async data => {
  const res = await nftApi.post("/category", data);
  return res.data;
};

const getCategory = async id => {
  const res = await nftApi.get("/category", {params: {id}});
  return res.data;
};

const updateCategory = async data => {
  const res = await nftApi.put("/category", data);
  return res.data;
};

const addPhotosToCollections = async data => {
  const res = await nftApi.post("/category/photo", data);
  return res.data;
};

/**
 * Get category and files in category by token
 * @param {String} token
 * @param {object} params params for filter files
 * @returns
 */
const getCategoryByToken = async (token, params) => {
  const config = {
    headers: {Authorization: `Bearer ${token}`},
    params,
  };
  const res = await nftApi.get("/share/category", config);

  return res.data;
};

const getShareToken = async (type, identity) => {
  const res = await nftApi.get(`/share/${type}/${identity}`);
  return res.data;
};

const fetchFileByToken = async (token, hash) => {
  const config = {
    headers: {Authorization: `Bearer ${token}`},
    params: {hash},
  };
  const res = await nftApi.get("/share/file", config);

  return res.data;
};

const getNextSequenceId = async data => {
  const res = await nftApi.post("/blockchain/nextSequenceId", data);

  return res.data;
};

const createNFTToken = async data => {
  const res = await nftApi.post("/blockchain/createToken", data);

  return res.data;
};

/**
 * Transfer NFT to new address
 * @param {Object} data
 * @param {String} data.sequenceId
 * @param {String} data.callData
 * @param {String} data.signature
 * @param {String} data.hash
 * @param {String} data.to
 */
const transferNFT = async data => {
  const res = await nftApi.post("/blockchain/transferFrom", data);

  return res.data;
};

/**
 * Transfer NFT to new address
 * @param {Object} data
 * @param {String} data.sequenceId
 * @param {String} data.callData
 * @param {String} data.signature
 * @param {String} data.hash
 * @param {String} data.to
 * @param {String} data.blockchain
 */
const safeTransferFrom = async data => {
  const res = await nftApi.post("/blockchain/safeTransferFrom", data);

  return res.data;
};

export const getFileStats = async () => {
  const res = await nftApi.get("/file/stats");

  return res.data;
};

export const sendMessageContact = async data => {
  const res = await nftApi.post("/message/contact", data);

  return res.data;
};

const nftService = {
  addAddress,
  getAccountInfo,
  updateAccountInfo,
  getAccountInfoPublished,
  getActiveAddresses,
  getAddresses,
  login,
  register,
  removeToken,
  setToken,
  updateAvatar,
  updatePassword,
  // file
  attach,
  deleteFile,
  downloadFile,
  fetchFileByHash,
  fetchFileList,
  fetchFileHavingNft,
  fetchFileNoCollection,
  getLatestFiles,
  fetchIssuedNFTs,
  fetchNFTs,
  getAttachedFiles,
  getNFTs,
  makeNFTs,
  publishFile,
  registerFile,
  signFile,
  signNFT,
  // transferFile,
  // category
  createCategory,
  fetchCategories,
  getLatestCategories,
  getCategory,
  updateCategory,
  addPhotosToCollections,
  // share
  fetchFileByToken,
  getCategoryByToken,
  getShareToken,
  getNextSequenceId,
  createNFTToken,
  // blockchain
  transferNFT,
  safeTransferFrom,
  getFileStats,
  // message
  sendMessageContact,
};

export default nftService;
