import React from "react";
import {authRoles} from "app/auth/index.js";

const myNFTConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.user,
  routes: [
    {
      path: "/mynft",
      exact: true,
      component: React.lazy(() => import("./MyNFTs.js")),
    },
  ],
};

export default myNFTConfig;
