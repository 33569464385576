const artwork = {
  "Name is required": "Name is required",
  "File is required": "File is required",

  "Explore all artworks": "Explore all artworks",
  "Filter artworks": "Filter artworks",

  "Total": "Total",

  "NFT thumbnail": "NFT thumbnail",
  "Show blockchain": "Show blockchain",
  "Copy token ID": "Copy token ID",

  "Original artwork": "Original artwork",
  "ORIGINAL": "ORIGINAL",
  "Publish artwork": "Publish artwork",
  "Download": "Download",
  "Download tooltip": "This file is solely available to NFT owner, used for home display or more uses depending on the granted license",
  "Download thumbnail": "Download thumbnail",
  "Download thumbnail tooltip": "This is the only item you should make public, and use for sharing on art platforms or marketplaces",
  "Share": "Share",
  "Share tooltip": "Get share link",
  "Contact": "Contact",
  "Contact tooltip": "Contact with owner",
  "Present in": "Present in",
  "Cancel": "Cancel",
  "Add to collection": "Add to collection",
  "Filter collections": "Filter collections",

  "Register": "Create",

  "New artwork": "New artwork",
  "Published": "Published",
  "Private": "Private",
  "NFT wallet": "NFT wallet",
  "NFTs issued": "NFTs issued",
  "My NFTs": "My NFTs",

  "This artwork will be published with everybody": "This artwork will be published with everybody",
  "You cannot undo this action": "You cannot undo this action",
  "Publish": "Publish",

  "transferable": "transferable",
  "transferred": "transferred",

  "Transfer": "Transfer",
  "Transfer NFT": "Transfer NFT",

  "Receiver address is required": "Receiver address is required",
  "Receiver address": "Receiver address",
  "Trading place is required": "Trading place is required",
  "Trading place": "Trading place",
  "Token ID is required": "Token ID is required",
  "Token ID on redirect contract": "Token ID on redirect contract",

  "and": "and",
  "more": "more",

  "Message is required": "Message is required",
  "Send": "Send",
  "Message": "Message",

  "NFT notification": "Thumbnail image should be used on NFT platform: <0 alt='artwork' href='{{link}}' target='_blank' rel='noreferrer'>download link</0>.\nThe actual NFT file (without watermark) should be reserved for verified owners. Some platforms enable some hidden text to be displayed to purchasers alone.\nYou may decide to paste the NFT download link there, or a means to contact you.\nNote that you should paste the NFT's IDX inside the description part of your NFT on target platform.\nEnjoy your safer journey.",
  "Create NFTs": "Create NFTs",
  "Publish all uploading photos": "Publish all uploading photos",
  "Generate an NFT for every uploading photo": "Generate an NFT for every uploading photo",
  "The photo or photos are digital twins for a physical work of art": "The photo or photos are digital twins for a physical work of art",
  "I certify that I have the right to perform this action": "I certify that I have the right to perform this action",

  "ID for searching": "ID for searching",
  "Full IDX": "Full IDX",
  "Name": "Name",
  "File": "File",
  "Choose file": "Choose file",
  "Attach file": "Attach file",
  "Attach": "Attach",
  "Attached files": "Attached files",
  "Attach new file": "Attach new file",

  "Delete artwork": "Delete",
  "Delete artwork tooltip": "Delete artwork",
  "This artwork and related file will be deleted": "This artwork and related file will be deleted",
  "You will not be able to reverse this action": "You will not be able to reverse this action",

  "Artwork title": "Artwork title prefix",
  "Artwork title placeholder": "Artwork title prefix - will be complemented with filename (excl. extension)",
  "Artwork title comment": "Leave artwork title prefix blank if useless",
  "Artwork description": "Artwork description",
  "Artwork description placeholder": "Artwork description (will be generic if you upload a series of photos)",
  "NFT description": "NFT description",
  "NFT description placeholder": "NFT description",
  "NFT license": "NFT license",
  "NFT license placeholder": "Provide NFT license name and its IDX (if keeexed).\nE.g: KeeeX NFT Basic License - xiker-tocyp-magib-miduc-filyh-kedad-lyzit-kypek-vopim-sevid-rohug-pavul-hiloc-timif-kidur-ramek-dixyx",
  "Creating: {{createdTokens}} / {{totalTokens}} KeeeX NFTs": "Creating: {{createdTokens}} / {{totalTokens}} KeeeX NFTs.",
  "Note: don't close this browser tab while processing": "Note: don't close this browser tab while processing.",
  "See": "See",
  "Create [{{name}}] collection": "Create [{{name}}] collection",
  "Delete": "Delete",
  "My public address": "My public address",
  "Artwork": "Artwork",

  "N NFTs_one": "{{count}} NFT",
  "N NFTs_other": "{{count}} NFTs",
  "N NFTs available_one": "{{count}} NFT minted",
  "N NFTs available_other": "{{count}} NFTs minted",
  "N NFTs transferable_one": "{{count}} NFT transferable",
  "N NFTs transferable_other": "{{count}} NFTs transferable",
  "N NFTs transferred_one": "{{count}} NFT transferred",
  "N NFTs transferred_other": "{{count}} NFTs transferred",
  "Present in N collections_one": "Present in {{count}} collection",
  "Present in N collections_other": "Present in {{count}} collections",
  "Total: N artworks_one": "Total: {{count}} artwork",
  "Total: N artworks_other": "Total: {{count}} artworks",
  "Total: N NFTs_one": "Total: {{count}} NFT",
  "Total: N NFTs_other": "Total: {{count}} NFTs",

  "Collection and N more": "{{name}} and {{count}} more",

  "View all artworks": "View all artworks",
  "View artworks having NFT": "View artworks having NFT",
  "View artworks presenting in a collection": "View artworks presenting in a collection",
  "View all my artworks": "View all my artworks",
  "View public artworks only": "View public artworks only",
  "View private artworks only": "View private artworks only",
  "View artworks not presented in any collection": "View artworks not presented in any collection",
  "View all my NFTs": "View all my NFTs",
  "View NFTs issued by me": "View NFTs issued by me",
  "View NFTs received from someone": "View NFTs received from someone",
  "View NFTs transferred to someone": "View NFTs transferred to someone",

  "Create some KeeeX NFTs for this artwork": "Create some KeeeX NFTs for this artwork",
  "Number of KeeeX NFTs": "Number of KeeeX NFTs",
  "Submit": "Submit",

  "Redirect message": "Migrate this NFT to some other blockchain / smart contract instead of sending to other portal user.",
  "Redirect warning message": "Be very careful, neither internal transfer or migration operations can be reverted.",
};

export default artwork;
