import {setUserData} from "app/auth/store/actions/user.action.js";
import {FileVisibilities} from "app/helpers/constants.js";
import {showError, showSuccess} from "app/helpers/toastMessage.js";
import nftService from "app/services/nft.js";

export const PROFILE_ERROR = "PROFILE_ERROR";

export const PROFILE_SET_LOAD_STATE = "PROFILE_SET_LOADING";

export const PROFILE_SET_ARTWORK_LIST = "PROFILE_SET_ARTWORK_LIST";
export const PROFILE_SET_COLLECTION_LIST = "PROFILE_SET_COLLECTION_LIST";
export const PROFILE_SET_USER = "PROFILE_SET_USER";

export const fetchCollections = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROFILE_SET_LOAD_STATE,
      payload: true,
    });

    const {collectionList} = getState().profile;

    const params = {
      page: collectionList.page,
      limit: collectionList.limit,
      accountId: collectionList.accountId,
      filesVisibility: FileVisibilities.everyone,
    };

    const data = await nftService.fetchCategories(params);

    dispatch({
      type: PROFILE_SET_COLLECTION_LIST,
      payload: data,
    });
  } catch (error) {
    showError(error);
    dispatch({
      type: PROFILE_ERROR,
      payload: error,
    });
  }
};

export const setCollectionFilter = value => dispatch => {
  dispatch({
    type: PROFILE_SET_COLLECTION_LIST,
    payload: value,
  });

  dispatch(fetchCollections());
};

export const fetchArtworks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROFILE_SET_LOAD_STATE,
      payload: {isLoading: true},
    });

    const {artworkList} = getState().profile;

    const params = {
      accountId: artworkList.accountId,
      limit: artworkList.limit,
      page: artworkList.page,
      visibility: FileVisibilities.everyone,
    };

    const data = await nftService.fetchFileList(params);

    dispatch({
      type: PROFILE_SET_ARTWORK_LIST,
      payload: data,
    });
  } catch (error) {
    showError(error);
    dispatch({
      type: PROFILE_ERROR,
      payload: {...error, isLoading: false},
    });
  }
};

export const setArtworkFilter = value => dispatch => {
  dispatch({
    type: PROFILE_SET_ARTWORK_LIST,
    payload: value,
  });

  dispatch(fetchArtworks());
};

export const getUser = username => async dispatch => {
  try {
    const user = await nftService.getAccountInfoPublished(username);

    dispatch({
      type: PROFILE_SET_USER,
      payload: user,
    });

    return user;
  } catch (error) {
    showError(error);

    dispatch({
      type: PROFILE_ERROR,
      payload: error,
    });
  }
};

export const updateAvatar = file => async dispatch => {
  try {
    const formData = new FormData();
    const config = {headers: {"content-type": `multipart/form-data; boundary=${formData._boundary}`}};

    formData.append("file", file);

    const user = await nftService.updateAvatar(formData, config);

    dispatch({
      type: PROFILE_SET_USER,
      payload: user,
    });

    showSuccess("Your avatar updated successfully");

    dispatch(setUserData({data: user}));

    return user;
  } catch (error) {
    showError(error);

    dispatch({
      type: PROFILE_ERROR,
      payload: error,
    });
  }
};

export const updateProfile = data => async dispatch => {
  try {
    const user = await nftService.updateAccountInfo(data);

    dispatch({
      type: PROFILE_SET_USER,
      payload: user,
    });

    dispatch(setUserData({data: user}));

    showSuccess("Your account updated!");

    return user;
  } catch (error) {
    showError(error);

    dispatch({
      type: PROFILE_ERROR,
      payload: error,
    });
  }
};
