import * as reduxModule from "redux";
import {applyMiddleware, compose, createStore} from "redux";

import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage/index.js";

import thunk from "redux-thunk";
import createReducer from "./reducers/index.js";

reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT";

// eslint-disable-next-line no-process-env
const composeEnhancers = process.env.NODE_ENV !== "production"
  && typeof window === "object"
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["settings"],
};

const persistedReducer = persistReducer(persistConfig, createReducer());
const store = createStore(persistedReducer, enhancer);
let persistor = persistStore(store);

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;

  store.replaceReducer(persistReducer(
    persistConfig,
    createReducer(store.asyncReducers),
  ));

  persistor = persistStore(store);

  return store;
};

const reduxStore = {store, persistor};

export default reduxStore;
