import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";

import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

import {LazyLoadImage} from "react-lazy-load-image-component";

import * as authActions from "app/auth/store/actions/index.js";

import {showSuccess} from "app/helpers/toastMessage.js";
import Utils from "app/helpers/utils.js";

import reducers from "app/main/profile/store/reducers/profile.reducer.js";
import withReducer from "app/store/with_reducer.js";
import {KeyType} from "@keeex/js-keys/lib/shared/types";

const list = [
  {
    pathname: "/myartwork/register",
    label: "Register new artwork",
  },
  {
    pathname: "/myartwork",
    label: "My artworks",
  },
  {
    pathname: "/mycollection",
    label: "My collections",
  },
  {
    pathname: "/mynft",
    label: "My NFTs",
  },
  {
    pathname: "/settings",
    label: "Preferences",
  },
];

// eslint-disable-next-line max-lines-per-function
const Popover = ({onClose, user}) => {
  const {t} = useTranslation("common");
  const _history = useHistory();
  const dispatch = useDispatch();

  const ethKey = useMemo(
    () => user?.data?.wallets?.find(e => e.type === KeyType.ethereum),
    [user?.data?.wallets],
  );

  const handleItemClick = useCallback(
    pathname => {
      _history.push(pathname);
      onClose();
    },
    [_history, onClose],
  );

  const handleProfileClick = useCallback(
    () => handleItemClick(`/profile/${user?.data?.username}`),
    [handleItemClick, user?.data?.username],
  );

  const handleCopyAddressClicked = useCallback(() => {
    Utils.copyToClipboard(ethKey?.address);
    showSuccess("Public address is copied to clipboard!");
  }, [ethKey?.address]);

  return (
    <div className="popover card">
      <div
        className="line is-flex is-align-items-end"
        onClick={handleProfileClick}
      >
        <LazyLoadImage
          className="has-background-white"
          src={user?.data?.avatar || "/logo/avatar_sample.png"}
        />
        <div className="profile ml-5">
          <p className="is-size-5">{user?.data?.name}</p>
          <p className="is-size-6">{user?.data?.username}</p>
        </div>
      </div>
      <div className="is-divider my-0" />

      {ethKey && (
        <div className="p-4">
          <p className="has-text-grey is-size-7">{t("Public address")}:</p>
          <div className="media">
            <div className="media-content">
              <p className="truncate">
                {ethKey.address}
              </p>
            </div>
            <div className="media-right">
              <span
                className="icon has-text-primary is-clickable has-tooltip-arrow"
                data-tooltip={t("Copy address")}
                onClick={handleCopyAddressClicked}
              >
                <i className="fas fa-copy" />
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="is-divider my-0" />
      {list.map(e => (
        <div
          key={e.pathname}
          className="line"
          // name="1"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => handleItemClick(e.pathname)}
        >
          {t(e.label)}
        </div>
      ))}
      <div className="is-divider my-0" />
      <div
        className="line logout"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          dispatch(authActions.logoutUser());
          handleItemClick("/");
        }}
      >
        {t("Logout")}
      </div>
    </div>
  );
};

Popover.defaultProps = {
  onClose: () => {},
  user: {
    data: {
      avatar: "",
      name: "",
      username: "",
      wallets: [],
    },
  },
};

Popover.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
};
Popover.displayName = "Popover";

export default withReducer("profile", reducers)(Popover);
