import {toast, Zoom} from "react-toastify";

export const showError = message => {
  let errorMessage = "";
  if (typeof message === "string") {
    errorMessage = message;
  } else if (message.response
    && message.response.data
    && message.response.data.error) {
    errorMessage = message.response.data.error;
  } else {
    errorMessage = message.message;
  }

  toast(errorMessage, {
    type: toast.TYPE.ERROR,
    transition: Zoom,
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 8000,
    // hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });

  return false;
};

export const showInfo = message => toast(message, {
  type: toast.TYPE.INFO,
  transition: Zoom,
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 3000,
  // hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
});

export const showSuccess = message => toast(message, {
  type: toast.TYPE.SUCCESS,
  transition: Zoom,
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 3000,
  // hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
});

export const showWarning = message => {
  toast(message, {
    type: toast.TYPE.WARNING,
    transition: Zoom,
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 3000,
    // hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });

  return false;
};

const toastMessage = {
  showError,
  showInfo,
  showSuccess,
  showWarning,
};

export default toastMessage;
