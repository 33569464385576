import {showError} from "app/helpers/toastMessage.js";
import nftService from "app/services/nft.js";

export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const register = values => async dispatch => {
  try {
    const data = await nftService.register(
      values.email,
      values.password,
      values.name,
      values.username,
    );

    dispatch({type: REGISTER_SUCCESS});

    return data;
  } catch (error) {
    showError(error);

    dispatch({
      type: REGISTER_ERROR,
      payload: error.response ? error.response.data : error,
    });
  }
};
