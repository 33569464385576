/* eslint quote-props: ["error", "consistent"] */

const collection = {
  "Explore all collections": "Explore all collections",
  "Filter collections": "Filter collections",
  "Total": "Total",

  "Get share link": "Get share link",
  "Update collection": "Update collection",
  "New collection": "New collection",

  "Collection title": "Collection title",
  "Collection description": "Collection description",
  "Add artworks into this collection": "Add artworks into this collection",
  "Update": "Update",
  "Create": "Create",
  "Cancel": "Cancel",

  "Total: N collections_one": "Total: {{count}} collection",
  "Total: N collections_other": "Total: {{count}} collections",
};

export default collection;
