import React from "react";
import {authRoles} from "app/auth/index.js";

// import i18next from "i18next";
import i18next from "../../../i18n.js";

import search from "./i18n/en.js";
import fr from "./i18n/fr.js";

i18next.addResourceBundle("en", "search", search);
i18next.addResourceBundle("fr", "search", fr);

const SearchConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/search",
      exact: true,
      component: React.lazy(() => import("./Search.js")),
    },
  ],
};

export default SearchConfig;
