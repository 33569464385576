/* eslint quote-props: ["error", "consistent"] */
const common = {
  // Menu
  "Artworks": "Oeuvres",
  "Collections": "Collections",
  "Search": "Recherche",
  "About": "À propos de",
  "How": "Comment",
  "Why": "Pourquoi",

  "Explore": "Explorer",
  "You": "Vous",

  "My profile": "Mon profil",
  "Register new artwork": "Enregistrer une nouvelle œuvre",
  "My artworks": "Mes oeuvres",
  "My collections": "Mes collections",
  "My NFTs": "Mes NFTs",
  "Preferences": "Préférences",

  "Logout": "Se déconnecter",
  "Login": "Connexion",

  // Layout
  "This is beta version": "Ceci est la version beta",

  "available": "available",

  // pagination
  "Empty data": "Données vides",
  "items per page": "objets par page",
  "of": "de",
  "Previous page": "Page précédente",
  "Previous": "Précédent",
  "Next page": "Page suivante",
  "Next": "Prochain",

  // Dropzone
  "Drop files here or click to upload": "Déposez les fichiers ici ou cliquez pour télécharger",
  "Or": "Ou",
  "Click to select files": "Cliquez pour sélectionner des fichiers",
  "Drop some photos here": "Déposez des photos ou un dossier ici",
  "N files selected_one": "{{count}} fichier sélectionné",
  "N files selected_other": "{{count}} fichiers sélectionnés",

  // VisitorWrapper
  "We still need to keep this access closed to unwanted people": "Cet accès est fermé aux clients non invités ou non payants.",
  "Please contact us if you want to access this page": "Veuillez nous contacter si vous souhaitez accéder à cette fonctionnalité:",

  //
  "item_one": "élément",
  "item_other": "éléments",

  "artwork_one": "œuvre d'art",
  "artwork_other": "œuvres d'art",

  "collection_one": "collection",
  "collection_other": "collections",

  "NFT_one": "NFT",
  "NFT_other": "NFTs",

  "Public address": "Adresse publique",
  "Copy address": "Copier l'adresse",
  "Copy": "Copie",

  "N artworks_one": "{{count}} œuvre d'art",
  "N artworks_other": "{{count}} œuvres d'art",
  "N collections_one": "{{count}} collection",
  "N collections_other": "{{count}} collections",

  "Why KeeeX NFT?": "Pourquoi KeeeX NFT?",
  "How KeeeX NFT works?": "Comment fonctionne KeeeX NFT?",
  "Partners": "Partenaires",
  "Contact": "Contact",
  "Terms of service": "Conditions générales de service",
  "Terms of Use": "Conditions Générales d'Utilisation",

  "Go back to homepage": "Retourner à la page d'accueil",
};

export default common;
