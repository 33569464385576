import React from "react";
import {authRoles} from "app/auth/index.js";

import i18next from "../../../i18n.js";
import en from "./i18n/en.js";
import fr from "./i18n/fr.js";

i18next.addResourceBundle("en", "about", en);
i18next.addResourceBundle("fr", "about", fr);

const ArtworkConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/how-keeex-nft-works",
      exact: true,
      component: React.lazy(() => import("./How.js")),
    },
    {
      path: "/why-keeex-nft",
      exact: true,
      component: React.lazy(() => import("./Why.js")),
    },
    {
      path: "/partner",
      exact: true,
      component: React.lazy(() => import("./Partner.js")),
    },
    {
      path: "/terms-of-use",
      exact: true,
      component: React.lazy(() => import("./TermsOfUse.js")),
    },
  ],
};

export default ArtworkConfig;
